import 'regenerator-runtime/runtime'

import "./app/menu/Menu";
import "./app/accueil/Accueil";
import "./app/services/Services";
import "./app/valeurs/Valeurs";
import "./app/equipe/Equipe";
import "./app/contact/Contact";
import "./app/footer/Footer";

window.onload = async function(){
}